import { useFloating } from '@floating-ui/react';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { EditorContent, ReactRenderer, useEditor } from '@tiptap/react';
import {
  type SuggestionKeyDownProps,
  type SuggestionProps,
} from '@tiptap/suggestion';
import { useMemo, useRef } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';
import Select from 'react-select';
import useSWR from 'swr';

import {
  ClientMessageType,
  EnumsPromptTemplateType,
  type ModelsPromptMessage,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { DeleteIcon } from '../icons/DeleteIcon';
import { Loading } from '../Loading';

const msgTypeOptions: Option<ClientMessageType>[] = [
  {
    value: ClientMessageType.MESSAGETYPE_SYSTEM,
    label: ClientMessageType.MESSAGETYPE_SYSTEM,
  },
  {
    value: ClientMessageType.MESSAGETYPE_HUMAN,
    label: ClientMessageType.MESSAGETYPE_HUMAN,
  },
  {
    value: ClientMessageType.MESSAGETYPE_AI,
    label: ClientMessageType.MESSAGETYPE_AI,
  },
];

const SnippetItem = (props: {
  isActive: boolean;
  children: React.ReactNode;
  onMouseEnter: () => void;
  onClick: () => void;
}) => {
  const { isActive } = props;
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [isActive]);

  return (
    <div
      ref={ref}
      className={`px-1 border-b border-secondary rounded-lg ${
        isActive ? 'bg-secondary' : ''
      }`}
      onMouseEnter={props.onMouseEnter}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

type SnippetListActions = {
  onKeyDown: (props: SuggestionKeyDownProps) => boolean;
};

export const SnippetList = forwardRef<SnippetListActions, SuggestionProps>(
  ({ clientRect, command, query }, ref) => {
    const { data, isLoading } = useSWR(
      '/prompt-templates/search?type=snippet',
      async () => {
        const resp = await apiService.promptTemplate.searchTemplates({
          type: EnumsPromptTemplateType.PromptTemplateTypeSnippet,
        });
        return resp.data.promptTemplates;
      }
    );
    const snippets = useMemo(
      () =>
        data?.filter((t) =>
          t.name.toLowerCase().includes(query.toLowerCase())
        ) ?? [],
      [data, query]
    );

    const handleCommand = (index: number) => {
      const selected = snippets[index];
      command({ id: selected.id, label: selected.name });
    };

    const [hoverIndex, setHoverIndex] = useState(0);
    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        const { key } = event;

        if (key === 'ArrowUp') {
          setHoverIndex((prev) => {
            const beforeIndex = prev - 1;
            return beforeIndex >= 0 ? beforeIndex : 0;
          });
          return true;
        }

        if (key === 'ArrowDown') {
          setHoverIndex((prev) => {
            const afterIndex = prev + 1;
            const count = snippets.length - 1 > 0 ? snippets.length - 1 : 0;
            return afterIndex < count ? afterIndex : count;
          });
          return true;
        }

        if (key === 'Enter') {
          handleCommand(hoverIndex);
          return true;
        }

        return false;
      },
    }));

    const { refs, floatingStyles } = useFloating({
      placement: 'bottom-start',
    });

    const { setPositionReference } = refs;

    useEffect(() => {
      setPositionReference({
        getBoundingClientRect: () => {
          const rect = clientRect?.();
          return {
            x: rect?.x ?? 0,
            y: rect?.y ?? 0,
            width: rect?.width ?? 0,
            height: rect?.height ?? 0,
            top: rect?.top ?? 0,
            right: rect?.right ?? 0,
            bottom: rect?.bottom ?? 0,
            left: rect?.left ?? 0,
          };
        },
      });
    }, [clientRect, setPositionReference]);

    return createPortal(
      <div
        ref={refs.setFloating}
        className='bg-black text-white border border-secondary rounded-lg text-sms'
        style={floatingStyles}
      >
        {isLoading && <Loading imgClassName='w-5 h-5' />}
        {snippets.map((snippet, index) => (
          <SnippetItem
            key={snippet.id}
            isActive={index === hoverIndex}
            onMouseEnter={() => setHoverIndex(index)}
            onClick={() => handleCommand(index)}
          >
            {snippet.name}
          </SnippetItem>
        ))}
      </div>,
      document.body
    );
  }
);

export function PromptMessageEditor(props: {
  message: ModelsPromptMessage;
  onChange: (message: ModelsPromptMessage) => void;
  typeSelectDisabled?: boolean;
  disabled: boolean;
  height?: `h-${number}`;
  onRemove?: () => void;
  typeSelectVisible?: boolean;
  enableMention?: boolean;
  preview?: boolean;
}) {
  const { message, onChange, onRemove, typeSelectVisible } = props;
  const disabled = props.disabled || props.preview;
  const styles = useMemo(() => buildReactSelectStyles(), []);

  const extensions = useMemo(() => {
    const extensions = [Document, Paragraph, Text, History];
    if (props.enableMention) {
      extensions.push(
        Mention.configure({
          renderText: ({ options, node }) => {
            return `${options.suggestion.char}${node.attrs.label}[${node.attrs.id}]`;
          },
          renderHTML: ({ options, node }) => {
            return [
              'a',
              {
                'data-type': 'mention',
                href: `/admin/prompt-templates/${node.attrs.id}`,
                target: '_blank',
                class: 'bg-lp-blue-002 px-1 rounded-lg underline',
              },
              `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
            ];
          },
          suggestion: {
            char: '$',
            decorationTag: 'a',
            render: () => {
              let reactRenderer: ReactRenderer<SnippetListActions>;

              return {
                onStart: (props) => {
                  reactRenderer = new ReactRenderer(SnippetList, {
                    props,
                    editor: props.editor,
                  });
                },

                onUpdate(props) {
                  reactRenderer?.updateProps(props);
                },

                onKeyDown(props) {
                  if (props.event.key === 'Escape') {
                    reactRenderer?.destroy();
                    return true;
                  }

                  return reactRenderer?.ref?.onKeyDown(props) ?? false;
                },

                onExit() {
                  reactRenderer.destroy();
                },
              };
            },
          },
        })
      );
    }
    return extensions;
  }, [props.enableMention]);

  const editor = useEditor({
    extensions,
    editorProps: {
      attributes: {
        class: `field border-none py-2 mb-0 overflow-y-auto scrollbar ${
          props.height ?? 'h-40'
        } ${disabled ? 'cursor-not-allowed opacity-50' : ''}`,
      },
    },
    editable: !disabled,
    content:
      props.message.json ??
      props.message.text
        .split('\n')
        .map((line) => `<p>${line}</p>`)
        .join(''),
    onUpdate: ({ editor }) => {
      props.onChange({
        ...props.message,
        json: editor.getJSON(),
        text: editor.getText({ blockSeparator: '\n' }),
      });
    },
    onBlur: ({ editor }) => {
      props.onChange({
        ...props.message,
        json: editor.getJSON(),
        text: editor.getText({ blockSeparator: '\n' }),
      });
    },
  });

  useEffect(() => {
    if (!editor) return;
    editor.setEditable(!disabled);
  }, [editor, disabled]);

  const { data: previewText } = useSWR(
    () => {
      if (!props.preview || !editor) return null;
      const text = editor.getText();
      return ['/prompt-templates/preview-message', text];
    },
    async () => {
      const text = editor?.getText({ blockSeparator: '\n' });
      if (!text) return null;
      const resp = await apiService.promptTemplate.previewMessage({ text });
      return resp.data.text;
    }
  );

  if (!editor) return null;

  return (
    <div
      className={`relative rounded-xl border border-secondary border-solid 
    bg-black ${disabled ? '' : 'hover:border-primary focus:border-primary'}`}
    >
      <div className='flex items-start justify-between m-1'>
        <Select<Option<ModelsPromptMessage['type']>>
          classNamePrefix='select-box-v2'
          className={`w-40 ${typeSelectVisible ? '' : 'hidden'}`}
          styles={styles}
          value={{ label: message.type, value: message.type }}
          onChange={(e) => {
            if (e?.value) onChange({ ...message, type: e.value });
          }}
          options={msgTypeOptions}
          isSearchable={false}
          isDisabled={disabled || props.typeSelectDisabled}
          formatOptionLabel={(option) => option.label.toUpperCase()}
        />
        {onRemove && (
          <button
            type='button'
            className='text-red-002 icon-btn w-6 h-6'
            onClick={() => onRemove()}
          >
            <DeleteIcon />
          </button>
        )}
      </div>
      {props.preview ? (
        <textarea
          className={`field border-none py-2 mb-0 overflow-y-auto scrollbar ${
            props.height ?? 'h-40'
          }`}
          disabled
          value={previewText ?? 'Loading...'}
        />
      ) : (
        <EditorContent editor={editor} style={{}} />
      )}
    </div>
  );
}
