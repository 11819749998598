import { ErrorMessage } from '@hookform/error-message';
import { uuid4 } from '@sentry/utils';
import { useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Select from 'react-select';

import {
  ClientLLMProvider,
  ClientMessageType,
  type DtoPromptTemplate,
  EnumsPromptTemplateType,
  type ModelsFunctionDefinition,
  type ModelsPromptMessage,
  type ModelsPromptTemplateSettings,
  type ModelsVariableDefinition,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { DeleteIcon } from '../icons/DeleteIcon';
import { Loading } from '../Loading';
import { PromptMessageEditor } from './PromptMessageEditor';
import { ModelSelect, ProviderSelect } from './PromptTemplateVendor';
import { type ProviderItem } from './types';
import { PromptTemplateUtils } from './utils';

type FunctionDefinition = Omit<ModelsFunctionDefinition, 'parameters'> & {
  parameters: string;
};

type FormData = {
  name: string;
  provider: string;
  model: string;
  type: EnumsPromptTemplateType;
  systemPrompt: {
    text: string;
    json?: ModelsPromptMessage['json'];
  };
  functions: FunctionDefinition[];
  variables: ModelsVariableDefinition[];
  settings: ModelsPromptTemplateSettings;
  messages: ModelsPromptMessage[];
};

function safePrettyParameters(parameters: string | undefined) {
  if (!parameters) return '';
  try {
    return JSON.stringify(JSON.parse(parameters), null, 2);
  } catch (error) {
    return parameters;
  }
}

function FunctionEditor(props: {
  functions: FunctionDefinition[];
  initial: FunctionDefinition | null;
  onCancel: () => void;
  onSave: (fn: FunctionDefinition) => void;
  editing: boolean;
}) {
  const { functions } = props;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FunctionDefinition>({
    defaultValues: {
      ...props.initial,
      id: props.initial?.id ?? uuid4(),
      parameters: safePrettyParameters(props.initial?.parameters),
    },
  });

  function submit(data: FunctionDefinition) {
    if (!props.editing) return;
    if (functions.some((fn) => fn.id !== data.id && fn.name === data.name)) {
      setError('name', {
        type: 'custom',
        message: 'Function names must be unique.',
      });
      return;
    }
    if (
      data.required &&
      functions.some((fn) => fn.id !== data.id && fn.required)
    ) {
      setError('required', {
        type: 'custom',
        message: 'Only one function can turn on Force Call.',
      });
      return;
    }
    try {
      const json = JSON.parse(data.parameters);
      if (!json || typeof json !== 'object' || Array.isArray(json)) {
        throw new Error('Invalid JSON Object');
      }
      data.parameters = JSON.stringify(json);
    } catch (error) {
      setError('parameters', {
        type: 'custom',
        message: 'The parameters is not a valid JSON Object.',
      });
      return;
    }
    props.onSave(data);
  }

  return (
    <form
      className='w-full flex flex-col gap-4 text-white'
      onSubmit={handleSubmit((data) => {
        submit(data);
      })}
    >
      <div className='text-xl text-center'>{`${
        props.editing ? (!props.initial ? 'Add' : 'Update') : 'View'
      } Function Definition`}</div>
      <div>
        <p className='text-sms mb-1 font-bold'>Name:</p>
        <input
          {...register('name', { required: true })}
          className={`${errors.name ? 'field-error' : 'field'} mb-0`}
          disabled={!props.editing}
        />
        <ErrorMessage
          errors={errors}
          name='name'
          render={({ message }) => (
            <div className='text-sms text-red-002'>{message}</div>
          )}
        />
      </div>
      <div>
        <p className='text-sms mb-1 font-bold'>Description:</p>
        <input
          {...register('description', { required: true })}
          className={`${errors.description ? 'field-error' : 'field'} mb-0`}
          disabled={!props.editing}
        />
        <ErrorMessage
          errors={errors}
          name='description'
          render={({ message }) => (
            <div className='text-sms text-red-002'>{message}</div>
          )}
        />
      </div>
      <div className='flex items-center gap-4'>
        <div className='flex items-center gap-4'>
          <p className='text-sms font-bold'>
            <a
              href='https://platform.openai.com/docs/guides/structured-outputs/introduction'
              className='text-primary underline'
              target='_blank'
              rel='noreferrer'
            >
              Strict:
            </a>
          </p>
          <input
            {...register('strict')}
            type='checkbox'
            className='checkbox-dark w-5 h-5'
            disabled={!props.editing}
          />
        </div>
        <div className='flex items-center gap-4'>
          <p className='text-sms font-bold'>
            <a
              href='https://platform.openai.com/docs/guides/function-calling/configuring-function-calling-behavior-using-the-tool_choice-parameter'
              className='text-primary underline'
              target='_blank'
              rel='noreferrer'
            >
              Force Call:
            </a>
          </p>
          <input
            {...register('required')}
            type='checkbox'
            className='checkbox-dark w-5 h-5'
            disabled={!props.editing}
          />
          <ErrorMessage
            errors={errors}
            name='required'
            render={({ message }) => (
              <div className='text-sms text-red-002 mt-1'>{message}</div>
            )}
          />
        </div>
      </div>
      <div>
        <p className='text-sms mb-1 font-bold'>Parameters (JSON):</p>
        <textarea
          className={`flex h-76 py-2 px-2.5 resize-none mb-0 scrollbar ${
            errors.parameters ? 'field-error' : 'field'
          }`}
          disabled={!props.editing}
          {...register('parameters', {
            required: true,
          })}
        />
        <ErrorMessage
          errors={errors}
          name='parameters'
          render={({ message }) => (
            <div className='text-sms text-red-002 mt-1'>{message}</div>
          )}
        />
      </div>
      <div className='flex items-center justify-center gap-4'>
        {props.editing ? (
          <>
            <button
              type='button'
              className='btn-secondary w-40 h-10'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn-primary w-40 h-10'
              disabled={!props.editing}
            >
              Save
            </button>
          </>
        ) : (
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            onClick={props.onCancel}
          >
            Close
          </button>
        )}
      </div>
    </form>
  );
}

function FuntionListEditor(props: { editing: boolean }) {
  const { control } = useFormContext<FormData>();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const {
    fields: functions,
    append,
    update,
    remove,
  } = useFieldArray<FormData, 'functions', 'key'>({
    control: control,
    name: 'functions',
    keyName: 'key',
  });

  const onAdd = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-200'
          innerClassName='p-4'
          borderStyle='gray'
        >
          <FunctionEditor
            functions={functions}
            initial={null}
            editing
            onCancel={p.internalOnCancel}
            onSave={(fn) => {
              append(fn);
              p.internalOnConfirm();
            }}
          />
        </ModalWrapper>
      ),
    });
  };

  const onEdit = async (fn: FunctionDefinition, index: number) => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-200'
          innerClassName='p-4'
          borderStyle='gray'
        >
          <FunctionEditor
            functions={functions}
            initial={fn}
            editing={props.editing}
            onCancel={p.internalOnCancel}
            onSave={(fn) => {
              update(index, fn);
              p.internalOnConfirm();
            }}
          />
        </ModalWrapper>
      ),
    });
  };

  const onRemove = async (index: number) => {
    remove(index);
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='text-sms mb-1 flex items-center gap-10'>
        <p className='font-bold'>Functions:</p>
        <button
          type='button'
          className='btn text-primary underline'
          disabled={!props.editing}
          onClick={onAdd}
        >
          +Add
        </button>
      </div>
      <ul className='flex flex-col gap-3'>
        {functions.map((fn, idx) => (
          <li
            key={fn.id}
            className='flex items-center justify-between text-sms group'
          >
            <button
              type='button'
              className='underline'
              onClick={() => onEdit(fn, idx)}
            >
              {fn.name}
            </button>
            {props.editing && (
              <button
                type='button'
                className='text-red-002 hidden group-hover:block'
                onClick={() => onRemove(idx)}
              >
                <DeleteIcon />
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

function VariableEditor(props: {
  variables: ModelsVariableDefinition[];
  initial: ModelsVariableDefinition | null;
  onCancel: () => void;
  onSave: (v: ModelsVariableDefinition) => void;
  editing: boolean;
}) {
  const { variables } = props;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ModelsVariableDefinition>({
    defaultValues: {
      ...props.initial,
      id: props.initial?.id ?? uuid4(),
    },
  });

  function submit(data: ModelsVariableDefinition) {
    if (!props.editing) return;
    if (variables.some((v) => v.id !== data.id && v.name === data.name)) {
      setError('name', {
        type: 'custom',
        message: 'Variable names must be unique.',
      });
      return;
    }
    props.onSave(data);
  }

  return (
    <form
      className='w-full flex flex-col gap-4 text-white'
      onSubmit={handleSubmit((data) => {
        submit(data);
      })}
    >
      <div className='text-xl text-center'>{`${
        props.editing ? (!props.initial ? 'Add' : 'Update') : 'View'
      } Variable Definition`}</div>
      <div>
        <p className='text-sms mb-1 font-bold'>Name:</p>
        <input
          {...register('name', { required: true })}
          className={`${errors.name ? 'field-error' : 'field'} mb-0`}
          disabled={!props.editing}
        />
        <ErrorMessage
          errors={errors}
          name='name'
          render={({ message }) => (
            <div className='text-sms text-red-002'>{message}</div>
          )}
        />
      </div>
      <div>
        <p className='text-sms mb-1 font-bold'>Description:</p>
        <input
          {...register('description', { required: true })}
          className={`${errors.description ? 'field-error' : 'field'} mb-0`}
          disabled={!props.editing}
        />
        <ErrorMessage
          errors={errors}
          name='description'
          render={({ message }) => (
            <div className='text-sms text-red-002'>{message}</div>
          )}
        />
      </div>
      <div className='flex items-center gap-4'>
        <div className='flex items-center gap-4'>
          <p className='text-sms font-bold'>Required:</p>
          <input
            {...register('required')}
            type='checkbox'
            className='checkbox-dark w-5 h-5'
            disabled={!props.editing}
          />
          <ErrorMessage
            errors={errors}
            name='required'
            render={({ message }) => (
              <div className='text-sms text-red-002 mt-1'>{message}</div>
            )}
          />
        </div>
      </div>
      <div className='flex items-center justify-center gap-4'>
        {props.editing ? (
          <>
            <button
              type='button'
              className='btn-secondary w-40 h-10'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn-primary w-40 h-10'
              disabled={!props.editing}
            >
              Save
            </button>
          </>
        ) : (
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            onClick={props.onCancel}
          >
            Close
          </button>
        )}
      </div>
    </form>
  );
}

function SystemVariableRow() {
  return (
    <tr className='text-sms'>
      <td>
        <span>orgFacts</span>
      </td>
      <td>Organization custom facts</td>
      <td className='w-5'></td>
    </tr>
  );
}

function VariableListEditor(props: { editing: boolean }) {
  const { control, watch } = useFormContext<FormData>();
  const type = watch('type');
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const {
    fields: variables,
    append,
    update,
    remove,
  } = useFieldArray<FormData, 'variables', 'key'>({
    control: control,
    name: 'variables',
    keyName: 'key',
  });

  const onAdd = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-160'
          innerClassName='p-4'
          borderStyle='gray'
        >
          <VariableEditor
            variables={variables}
            initial={null}
            editing
            onCancel={p.internalOnCancel}
            onSave={(v) => {
              append(v);
              p.internalOnConfirm();
            }}
          />
        </ModalWrapper>
      ),
    });
  };

  const onEdit = async (v: ModelsVariableDefinition, index: number) => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-160'
          innerClassName='p-4'
          borderStyle='gray'
        >
          <VariableEditor
            variables={variables}
            initial={v}
            editing={props.editing}
            onCancel={p.internalOnCancel}
            onSave={(v) => {
              update(index, v);
              p.internalOnConfirm();
            }}
          />
        </ModalWrapper>
      ),
    });
  };

  const onRemove = async (index: number) => {
    remove(index);
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='text-sms mb-1 flex items-center gap-10'>
        <p className='font-bold'>Variables:</p>
        <button
          type='button'
          className='btn text-primary underline'
          disabled={!props.editing}
          onClick={onAdd}
        >
          +Add
        </button>
      </div>
      <table
        className='border-separate text-left'
        style={{ borderSpacing: '8px 0' }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {PromptTemplateUtils.SupportCustomFacts(type) && (
            <SystemVariableRow />
          )}
          {variables.map((v, idx) => (
            <tr key={v.name} className='text-sms group'>
              <td>
                <button
                  type='button'
                  className='underline'
                  onClick={() => onEdit(v, idx)}
                >
                  {v.name}
                  {v.required ? '*' : ''}
                </button>
              </td>
              <td>{v.description}</td>
              <td className='w-5'>
                {props.editing && (
                  <button
                    type='button'
                    className='text-red-002 hidden group-hover:block'
                    onClick={() => onRemove(idx)}
                  >
                    <DeleteIcon />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function SystemMessageSeparator(props: {
  type: 'start' | 'end';
  onClick?: () => void;
  collapsed?: boolean;
}) {
  return (
    <div
      className={`flex items-center gap-2 text-icon-gray ${
        props.onClick ? 'cursor-pointer hover:text-gray-300' : ''
      }`}
      onClick={props.onClick}
    >
      <div className='flex-1 border-b border-dashed border-gray-500'></div>
      <div className='text-sms flex items-center gap-1'>
        {props.collapsed ? (
          <>
            <span>Click to show system message</span>
            <span className='text-xs opacity-75'>[Custom Facts]</span>
          </>
        ) : (
          <>{props.type === 'start' ? '▼' : '▲'} System generated message</>
        )}
      </div>
      <div className='flex-1 border-b border-dashed border-gray-500'></div>
    </div>
  );
}

function CustomFactsMessage() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='my-2 flex flex-col gap-2'>
      {expanded ? (
        <>
          <SystemMessageSeparator
            type='start'
            onClick={() => setExpanded(false)}
          />
          <div className='bg-gray-900 bg-opacity-25'>
            <div className='px-4 py-2 text-xs text-gray-400'>
              This message will only be injected when organization has custom
              facts configured
            </div>
            <PromptMessageEditor
              message={{
                type: ClientMessageType.MESSAGETYPE_HUMAN,
                text: `For additional context, here are some facts about the organization you're working for. No need to acknowledge these facts directly in your response - they're just provided as additional context in case they might be relevant.

<customFacts>
{orgFacts}
</customFacts>`,
              }}
              onChange={() => void 0}
              typeSelectDisabled
              typeSelectVisible
              disabled={true}
              height='h-40'
            />
          </div>
          <SystemMessageSeparator type='end' />
        </>
      ) : (
        <SystemMessageSeparator
          type='start'
          onClick={() => setExpanded(true)}
          collapsed={true}
        />
      )}
    </div>
  );
}

function AdditionalMessages(props: {
  disabled: boolean;
  addable: boolean;
  preview: boolean;
}) {
  const { control } = useFormContext<FormData>();
  const {
    fields: messages,
    append,
    update,
    remove,
  } = useFieldArray<FormData, 'messages', 'key'>({
    control: control,
    name: 'messages',
    keyName: 'key',
  });

  const onAdd = () => {
    const lastMsg: ModelsPromptMessage | undefined =
      messages[messages.length - 1];
    append({
      type:
        lastMsg?.type === ClientMessageType.MESSAGETYPE_HUMAN
          ? ClientMessageType.MESSAGETYPE_AI
          : ClientMessageType.MESSAGETYPE_HUMAN,
      text: '',
    });
  };

  return (
    <>
      {messages.map((msg, idx) => (
        <PromptMessageEditor
          key={idx}
          message={msg}
          onChange={(msg) => {
            update(idx, msg);
          }}
          disabled={props.disabled}
          onRemove={() => remove(idx)}
          typeSelectVisible
          enableMention
          preview={props.preview}
        />
      ))}
      {props.addable && (
        <button
          type='button'
          className='btn text-primary underline text-sms self-start'
          onClick={onAdd}
          disabled={props.disabled}
        >
          Add Message
        </button>
      )}
    </>
  );
}

export function PromptTemplateEditor(props: {
  template: Nullable<DtoPromptTemplate>;
  type: EnumsPromptTemplateType;
  initialEditing: boolean;
  onSave: () => void;
  onCancel: () => void;
}): JSX.Element {
  const { template } = props;
  const [editing, setEditing] = useState(props.initialEditing);
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const styles = useMemo(() => buildReactSelectStyles(), []);
  const [providers, setProviders] = useState<ProviderItem[]>([]);

  const form = useForm<FormData>({
    defaultValues: async () => {
      const resp = await apiService.aiGeneral.getGlobalSettings();
      const providers =
        props.type === EnumsPromptTemplateType.PromptTemplateTypeImageGen
          ? resp.data.imageGenProviders
          : resp.data.llmProviders;
      setProviders(providers);
      return {
        name: template?.name ?? '',
        provider: template?.provider ?? providers[0].name,
        model: template?.model ?? providers[0].models[0],
        type: props.type,
        systemPrompt: {
          text: template?.systemPrompt ?? '',
          json: template?.systemPromptJSON ?? undefined,
        },
        functions: template?.functions ?? [],
        variables: template?.variables ?? [],
        settings: template?.settings ?? {
          fileSearchEnhancement: false,
          includeCustomFacts: true,
        },
        messages: template?.messages ?? [],
      };
    },
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isLoading },
    reset: resetForm,
    setError,
  } = form;

  const {
    state: { state, error },
    call: submit,
    reset,
  } = useLiveAsyncCall(async (data: FormData) => {
    reset();
    if (
      data.type === EnumsPromptTemplateType.PromptTemplateTypeAssistant &&
      data.provider.toLowerCase() !==
        ClientLLMProvider.LLMPROVIDER_OPEN_AI.toLowerCase()
    ) {
      setError('provider', {
        type: 'custom',
        message: 'Assistant can only be used with OpenAI',
      });
      return;
    }
    if (template?.id) {
      await apiService.promptTemplate.updateTemplate(template.id, {
        ...data,
        systemPrompt: data.systemPrompt.text,
        systemPromptJSON: data.systemPrompt.json ?? undefined,
      });
    } else {
      await apiService.promptTemplate.createTemplate({
        ...data,
        systemPrompt: data.systemPrompt.text,
        systemPromptJSON: data.systemPrompt.json ?? undefined,
      });
    }
    resetEditing();
    props.onSave();
  });

  const resetEditing = () => {
    setEditing(false);
    setEditable(PromptTemplateUtils.Editable(template));
  };

  const unlock = async () => {
    if (!sessionStorage.getItem('prompt-template-edit-unlock')) {
      const response = await confirmCancel({
        kind: 'confirm-cancel',
        prompt: (
          <div className='text-white text-base text-center'>
            <div className='flex flex-col gap-2'>
              <div className='text-2xl font-medium text-tertiary'>WARNING</div>
              <ul className='text-sm list-disc list-inside text-left flex flex-col gap-2'>
                <li>
                  You can fine-tune the GPT role or rules in the system prompt.
                </li>
                <li>
                  The response format needs to be aligned with our code
                  handling, otherwise the game will be broken. Please don't mess
                  them up.
                </li>
                <li>For safety, please test the game after making changes.</li>
              </ul>
            </div>
          </div>
        ),
        confirmBtnLabel: 'OKAY',
        confirmBtnVariant: 'primary',
        confirmOnly: true,
      });
      sessionStorage.setItem('prompt-template-edit-unlock', 'true');
      if (response.result !== 'confirmed') return;
    }

    setEditing(true);
  };

  const [editable, setEditable] = useState(
    PromptTemplateUtils.Editable(template)
  );
  const [preview, setPreview] = useState(false);

  const provider = form.watch('provider');
  const includeCustomFacts = form.watch('settings.includeCustomFacts');

  const typeOptions = useInstance(() => PromptTemplateUtils.TypeOptions());

  if (isLoading) return <Loading />;

  return (
    <FormProvider {...form}>
      <form
        className='w-full flex flex-col gap-4 text-white'
        onSubmit={handleSubmit((data) => {
          submit(data);
        })}
      >
        <div className='flex gap-4'>
          <div className='w-2/3 flex flex-col gap-4'>
            <div className='flex items-center gap-4'>
              <div className='flex-grow'>
                <p className='text-sms mb-1 font-bold'>Name:</p>
                <input
                  {...register('name')}
                  className='field h-10 mb-0'
                  disabled={!editing}
                />
              </div>
              <div>
                <p className='text-sms mb-1 font-bold'>Type:</p>
                <Controller<FormData, 'type'>
                  control={control}
                  name='type'
                  render={({ field }) => (
                    <Select<Option<DtoPromptTemplate['type']>>
                      classNamePrefix='select-box-v2'
                      styles={styles}
                      value={{ label: field.value, value: field.value }}
                      onChange={(e) => {
                        if (e?.value) field.onChange(e.value);
                      }}
                      options={typeOptions}
                      isSearchable={false}
                      isDisabled={true}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className={`items-center gap-4 ${
                !PromptTemplateUtils.IsSnippet(props.type) ? 'flex' : 'hidden'
              }`}
            >
              <div className='w-1/2'>
                <p className='text-sms mb-1 font-bold'>Provider:</p>
                <Controller<FormData, 'provider'>
                  control={control}
                  name='provider'
                  render={({ field }) => (
                    <>
                      <ProviderSelect
                        providers={providers}
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        isDisabled={!editing}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='vendor'
                        render={({ message }) => (
                          <p className='text-red-002 text-sms'>{message}</p>
                        )}
                      />
                    </>
                  )}
                />
              </div>
              <div className='w-1/2'>
                <p className='text-sms mb-1 font-bold'>Model:</p>
                <Controller<FormData, 'model'>
                  control={control}
                  name='model'
                  render={({ field }) => (
                    <ModelSelect
                      value={field.value}
                      provider={provider}
                      onChange={field.onChange}
                      isDisabled={!editing}
                      providers={providers}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <div className='flex items-center justify-between'>
                <p className='text-sms mb-1 font-bold'>Messages:</p>
                <label className='text-sms flex items-center gap-2'>
                  <p>Show Preview</p>
                  <input
                    type='checkbox'
                    className='checkbox-dark w-4 h-4'
                    checked={preview}
                    onChange={(e) => setPreview(e.target.checked)}
                  />
                </label>
              </div>
              <div className='flex flex-col gap-2'>
                <Controller<FormData, 'systemPrompt'>
                  control={control}
                  name='systemPrompt'
                  render={({ field }) => (
                    <PromptMessageEditor
                      message={{
                        type: ClientMessageType.MESSAGETYPE_SYSTEM,
                        text: field.value.text,
                        json: field.value.json,
                      }}
                      typeSelectDisabled
                      height='h-80'
                      disabled={!editing}
                      onChange={(m) =>
                        field.onChange({
                          text: m.text,
                          json: m.json,
                        })
                      }
                      typeSelectVisible={
                        !PromptTemplateUtils.IsSnippet(props.type)
                      }
                      enableMention
                      preview={preview}
                    />
                  )}
                />
                {PromptTemplateUtils.SupportCustomFacts(props.type) &&
                  includeCustomFacts && <CustomFactsMessage />}
                <AdditionalMessages
                  disabled={!editing}
                  addable={PromptTemplateUtils.SupportMultiMessages(props.type)}
                  preview={preview}
                />
              </div>
            </div>
          </div>
          <div className='w-1/3 flex flex-col gap-10'>
            {PromptTemplateUtils.HasFeatureExtensions(props.type) && (
              <div className='flex flex-col gap-4'>
                {PromptTemplateUtils.SupportFileSearch(props.type) && (
                  <label className='flex items-center gap-4'>
                    <p className='text-sms mb-1 font-bold'>
                      File Search Enhancement (2-pass)
                    </p>
                    <Controller<FormData, 'settings.fileSearchEnhancement'>
                      control={control}
                      name='settings.fileSearchEnhancement'
                      render={({ field }) => (
                        <input
                          type='checkbox'
                          className='checkbox-dark w-5 h-5'
                          disabled={!editing}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  </label>
                )}
                {PromptTemplateUtils.SupportCustomFacts(props.type) && (
                  <label className='flex items-center gap-4'>
                    <p className='text-sms mb-1 font-bold'>
                      Include Custom Facts
                    </p>
                    <Controller<FormData, 'settings.includeCustomFacts'>
                      control={control}
                      name='settings.includeCustomFacts'
                      render={({ field }) => (
                        <input
                          type='checkbox'
                          className='checkbox-dark w-5 h-5'
                          disabled={!editing}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  </label>
                )}
              </div>
            )}
            {PromptTemplateUtils.SupportFunctionCall(props.type) && (
              <FuntionListEditor editing={editing} />
            )}
            <VariableListEditor editing={editing} />
          </div>
        </div>
        {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
        {editable ? (
          <div className='flex items-center justify-end gap-2 sticky right-0 bottom-0'>
            {!editing ? (
              <button
                className='btn-warning w-40 h-10 flex items-center justify-center'
                onClick={() => unlock()}
                type='button'
              >
                Edit
              </button>
            ) : (
              <button
                className='btn-secondary w-40 h-10 flex items-center justify-center'
                onClick={() => {
                  resetForm();
                  resetEditing();
                  props.onCancel();
                }}
                disabled={state.isRunning || isSubmitting}
                type='button'
              >
                Cancel
              </button>
            )}
            {editing && (
              <button
                type='submit'
                className='btn-primary w-40 h-10 flex items-center justify-center'
                disabled={state.isRunning || isSubmitting || !editing}
              >
                {state.isRunning || isSubmitting ? <Loading text='' /> : 'Save'}
              </button>
            )}
          </div>
        ) : (
          <div className='text-tertiary'>
            <span>The template owned by System,</span>
            <span
              className='text-primary underline cursor-pointer'
              onClick={() => {
                setEditable(true);
                setEditing(true);
              }}
            >
              Unlock
            </span>
            <span> to edit.</span>
          </div>
        )}
      </form>
    </FormProvider>
  );
}

export function usePromptTemplateEditorModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback((template: DtoPromptTemplate) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-4/5 min-w-240 h-full flex items-center'
          innerClassName='max-h-[80%] overflow-y-auto'
          borderStyle='gray'
        >
          <section className='flex flex-col items-center justify-center gap-5 p-10'>
            <header className='text-2xl font-medium'>
              Edit Prompt Template
            </header>
            <PromptTemplateEditor
              template={template}
              type={template.type}
              initialEditing
              onSave={() => {
                p.internalOnConfirm();
              }}
              onCancel={() => p.internalOnCancel()}
            />
          </section>
        </ModalWrapper>
      ),
    });
  });
}
